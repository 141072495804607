/* You can add global styles to this file, and also import other style files */
html {
  scroll-behavior: auto;
}

.pointer {
  cursor: pointer !important;
}

.c-hero__cta a:first-child {
  margin-right: 4px;
}

.e-tag--hash:before {
  content: '#';
}

.e-tag--pharm-employee:before {
  background-color: mediumpurple;
  display: inline-flex;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 4px;
}

.e-tag--other:before {
  background-color: gray;
  display: inline-flex;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 4px;
}
